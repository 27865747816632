$primaryColor: #9e292b;
$primaryColorOverlay: rgba(158, 41, 43, 0.12);
$secondaryColor: #3a424b;
$accentColor: #e98404;
$grayDarkColor: #3a424b;

$logoGradient: linear-gradient(35deg, #9e292b 35%, #f6a800);

$colorBeige: #e6e2e2;
$colorGrey: rgba(232, 232, 232, 0.5);

$logoBackground: url("/images/logo/cloud-transparent-mono.svg") no-repeat center;
$logoBackgroundTablet: url("/images/logo/cloud-transparent-mono-48-48.svg")
	no-repeat center;
$logoBackgroundMobile: url("/images/logo/logo-dbildungsMobile.svg") no-repeat
	center;
$navbarLogo: url("/images/logo/navbar-cloud-logo.svg") center / contain no-repeat;

aside.nav-sidebar {
	.sc-branding {
		padding: 0;
		.cloud-logo {
			width: 100%;
			margin: 0;
			padding: 30px;
		}
		.sc-title {
			display: none;
		}
	}

	@media (max-width: 991px) {
		.sc-branding {
			padding: 0;
			.cloud-logo {
				background: $logoBackgroundTablet !important;
				height: 32px;
			}
		}
	}
}

@media (max-width: 575px) {
	.cloud-logo {
		background: $logoBackgroundMobile !important;
	}
}

.primary-color {
	color: $primaryColor;
	display: none;
}
.secondary-color {
	color: $secondaryColor;
	display: none;
}
@import "./variables";
@import "../lib/bootstrap/scss/bootstrap";
// legacy css for login form
#loginarea {
	&:not(.demo-login) {
		border-radius: 0;
		border: 0px solid var(--color-gray-light);
		box-shadow: var(--shadow-sm);
	}
	.btn {
		word-wrap: break-word;
		white-space: normal;
		min-width: 22ch;
	}
	.toggle-btn {
		margin-bottom: 1rem;
	}
	input:not(.chosen-search-input),
	input:not(.chosen-search-input):-webkit-autofill::first-line,
	input:not(.chosen-search-input):-webkit-autofill:focus,
	input:not(.chosen-search-input):-webkit-autofill:hover {
		font-size: 1rem;
		padding: 0.5rem;
		font-family: $font-family-base;
	}
	select {
		width: 100%;
		padding: 0.25rem 0.5rem;
		border-radius: 4px;
	}
	.login-providers {
		display: none;
	}
	.email-login-section {
		display: none;
	}
	.ldap-login-section {
		display: none;
	}
	.system-display {
		display: none;
	}
	.separator-external-login {
		text-align: center;
    background-image: linear-gradient(to right, rgba(0,0,0,.15) 100%, transparent 50%);
    background-position: 0 58%;
    background-repeat: repeat-x;
    background-size: 1em 1px;
		margin: 1.2rem auto;
	}
	.separator-external-login-text {
		background: white;
		padding: 0 .3em
	}
}

.content-wrapper .loginarea_login {
	@include media-breakpoint-down(md) {
			margin-top: -35px;
	}
}

.login-failed-container {
	margin-top: 30px;
	max-width: 900px;

	.screenshot {
		width: 60%;

		@include media-breakpoint-down(md) {
			width: 80%;
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
		}
	}

	.steps {
		margin-bottom: 30px;
		margin-top: 10px;

		.sub-header {
			list-style: none;
			font-weight: 800;
			margin-bottom: 10px;
			font-size: 20px;
			&::before {
				display: none;
			}
		}

		.sub-step {
			margin-bottom: 20px;
			margin-top: 10px;
		}
	}
	.browser {
		margin-left: 10px 0px 0px 10px;
	}

	.logo-container {
		display: flex;
		flex-direction: column;

		.sc-logo {
			cursor: pointer;
			margin-left: 10px;
			margin-bottom: 15px;
		}
	}
}
